import Button from "../../Reusables/Button/Button"
import '../../../assets/css/TimeMachine/timeMachineNoDataModal.css';
import { useEffect, useContext } from "react";
import { AppContext } from "../../../Context/Context";

const TimeMachineNoDataModal = () => {
    const {isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen} = useContext(AppContext);

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('no-data-modal-wrapper-id')) {
            document.body.classList.remove('prevent-scroll');
            setIsNoHeatMapDataModalOpen(false);
        }
    })

    useEffect(() => {
        document.body.classList.add('prevent-scroll');
    }, [])

    const closeModal = () => {
        document.body.classList.remove('prevent-scroll');
        setIsNoHeatMapDataModalOpen(false);
    }

    return (
        <div id="no-data-modal-wrapper-id" className="no-data-modal-wrapper">
            <div className="no-data-modal-container">
                <div className="no-data-modal-title">
                    <h2>Nothing to Display here!</h2>
                </div>
                <div className="no-data-modal-sub-title">
                    <h4>No Data on the selected Heatmap Time Range<br/>Please select a different Time Range</h4>
                </div>
                <div className="no-data-modal-button-div">
                    <Button placeholder='Close' btnClass='no-data-modal-button' onClick={() => closeModal()}/>
                </div>
            </div>
        </div>
    )
}

export default TimeMachineNoDataModal;