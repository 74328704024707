import './datePicker.css';

const DatePicker = ({name, date, getDate, validation, min, max}) => {
    const pickDate = (e) => {
        getDate((date) => ({...date, [name]: e.target.value}));
    }
    return (
        <div className={`date-picker-wrapper ${!validation ? 'not-valid' : ''}`}>
            <label htmlFor={name} >Date <span>*</span></label>
            <input max={max} min={min} onChange={(e) => pickDate(e)} type='date' name={name} value={date ? date : ""} onKeyDown={(e) => e.preventDefault()}/>
        </div>
    )
}

export default DatePicker;