import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/Context";
import { setLocalStorageItem } from "../../../util/helper/helperFunctions";
import closeButton from "../../../assets/icons/close.png";
import { Progress } from "antd";

const BatteryStationObjectPopup = ({ stationData }) => {
  const [stationStatus, setStationStatus] = useState("");
  const { setOpenedBatteryStationName } = useContext(AppContext);
  const [chargingPercent, setChargingPercent] = useState(stationData.charging);

  useEffect(() => {
    setOpenedBatteryStationName(stationData.stationName);

    setStationStatus(stationData.status.split("/")[0]);
    setChargingPercent(stationData.charging);
    return () => {
      setOpenedBatteryStationName(null);
    };
  }, [stationData.status, stationData.charging]);

  const conicColors = {
    "0%": "#4fe733",
    "100%": "#27bf0b",
  };

  const { isMarkerPopupOpen, setIsMarkerPopupOpen } = useContext(AppContext);

  const [minimizeInitiated, setMinimizeInitiated] = useState(false);

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsMarkerPopupOpen(false);
  };

  return (
    <div id="battery-station-popup-id" className="battery-station-popup">
      <div
        className={`battery-station-container ${
          minimizeInitiated ? "modal-animation" : ""
        } status-${stationStatus.toLowerCase()}`}
      >
        <div className="battery-station-top-section">
          <div className="battery-station-header">
            <h3>{stationData.stationName}</h3>
            <div
              className={`battery-station-status battery-station-${stationStatus.toLowerCase()}`}
            >
              <span className={`status-text`}>{stationData.status}</span>
            </div>
          </div>
          <div className="modal-action-buttons">
            <div
              onClick={() => modalCloseHandler()}
              className="modal-action-button-wrapper"
            >
              <img className="modal-action-button" src={closeButton} />
            </div>
          </div>
        </div>
        <div className="battery-station-popup-bottom-section">
          <div className="bottom-item-container">
            {stationStatus == "Enabled" || stationStatus == "Disabled" ? (
              <div className="battery-station-status-icon">
                <svg
                  width="56"
                  height="57"
                  viewBox="0 0 56 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M50.7971 5.77207C50.097 5.07207 48.9537 5.07207 48.2537 5.77207L5.20371 48.8454C4.50371 49.5454 4.50371 50.6887 5.20371 51.3887C5.55371 51.7154 5.99704 51.9021 6.46371 51.9021C6.93038 51.9021 7.37371 51.7154 7.72371 51.3654L50.7971 8.29207C51.5204 7.59207 51.5204 6.47207 50.7971 5.77207Z"
                    fill="#65727D"
                  />
                  <path
                    opacity="0.4"
                    d="M34.5795 8.7821V21.9888L21.4195 35.1488V31.5554H14.2095C10.9429 31.5554 10.0329 29.5488 12.2029 27.0988L27.9995 9.1321L29.8662 7.00877C32.4562 4.06877 34.5795 4.8621 34.5795 8.7821Z"
                    fill="#65727D"
                  />
                  <path
                    opacity="0.4"
                    d="M43.7966 30.0387L27.9999 48.0054L26.1333 50.1287C23.5433 53.0687 21.4199 52.2754 21.4199 48.3554V42.1487L37.9866 25.582H41.7899C45.0566 25.582 45.9666 27.5887 43.7966 30.0387Z"
                    fill="#65727D"
                  />
                </svg>
                <span className="not-charging-text">Not Charging</span>
              </div>
            ) : stationStatus == "Charging" ? (
              <Progress
                className={`${chargingPercent < 100 ? "stroke-round" : ""}`}
                type="circle"
                size={180}
                status="normal"
                strokeLinecap="round"
                trailColor="#F1F3F5"
                percent={chargingPercent}
                // percent={100}
                strokeWidth={12}
                strokeColor={conicColors}
              />
            ) : (
              <Progress
                className={`label-text-hidden ${
                  chargingPercent < 100 ? "stroke-round" : ""
                }`}
                type="circle"
                size={180}
                status="normal"
                strokeLinecap="round"
                trailColor="#F1F3F5"
                percent={chargingPercent}
                strokeWidth={12}
                strokeColor={conicColors}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatteryStationObjectPopup;
