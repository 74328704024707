import { useEffect, useState, useContext } from "react";
import dragula from "dragula";
import { AppContext } from "../../Context/Context";
import { Table } from "antd";

const DraggableComponent = ({ id, draggableItems }) => {
  const [tableType, setTableType] = useState(null);
  const [isDragEnable, setIsDragEnable] = useState(false);
  const [dragItems, setDragItems] = useState(draggableItems);
  const {
    setDraggedItems,
    setWareHouseDraggedItems,
    showSaveDiscardLayout,
    isDefaultLayoutSelected,
    setWarehouseTempOrder,
  } = useContext(AppContext);

  useEffect(() => {
    if (id.length !== 0) {
      if (id === "warehouse-table-id") {
        setTableType(true);
      } else {
        setTableType(false);
      }
    }
  }, [id]);

  useEffect(() => {
    // Update local state when draggableItems change
    setDragItems(draggableItems);
  }, [draggableItems]); // Re-run effect when draggableItems change

  useEffect(() => {
    if (isDefaultLayoutSelected === false && showSaveDiscardLayout === true) {
      setIsDragEnable(true);
    } else {
      setIsDragEnable(false);
    }
  }, [isDefaultLayoutSelected, showSaveDiscardLayout]);

  useEffect(() => {
    // console.log("id", id, draggableItems);
    // Update local state when draggableItems change
    if (id === "dashboard-table-id") setDraggedItems(dragItems);
    else if (id === "warehouse-table-id") {
      setWareHouseDraggedItems(dragItems);
      let warehouseOrder = [];
      dragItems.forEach((item) => {
        warehouseOrder.push(item.key);
      });
      setWarehouseTempOrder(warehouseOrder);
    }
  }, [dragItems]);
  //false => dashboard features list
  //true => warehouse features list

  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);

  const columns = [
    {
      title: "Component",
      dataIndex: "component",
      key: "component",
    },
  ];

  const handleReorder = (dragIndex, draggedIndex) => {
    setDragItems((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };

  useEffect(() => {
    let start;
    let end;
    let container;
    // const container = document.querySelector(".ant-table-tbody");
    if (id == "warehouse-table-id") {
      container = document
        .getElementById("warehouse-table-id")
        .getElementsByClassName("ant-table-tbody")[0];
    } else if (id == "dashboard-table-id") {
      container = document
        .getElementById("dashboard-table-id")
        .getElementsByClassName("ant-table-tbody")[0];
    }
    const drake = dragula([container], {
      moves: (el) => {
        if (isDragEnable === false) return false;
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReorder(start, end);
    });

    return () => {
      drake.destroy();
    };
  }, [id, tableType, isDragEnable, draggableItems]);

  return (
    <Table
      id={id}
      columns={columns}
      pagination={false}
      dataSource={dragItems}
      draggable={isDragEnable}
    />
  );
};

export default DraggableComponent;
