import { useRef } from "react";
import "../../../assets/css/YardView/YardDetails.css";
import closeButton from "../../../assets/icons/close.png";
import { convertTimestampIntoHHMM } from "../../../util/helper/helperFunctions";
const YardDetails = ({ yardData = {}, setIsYardDetailModalOpen }) => {
  const popupRef = useRef(null);
  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsYardDetailModalOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target == popupRef.current) {
      modalCloseHandler();
    }
  });

  return (
    <div
      ref={popupRef}
      id="yard-details-popup-wrapper-id"
      className="yard-details-popup-wrapper"
    >
      <div className={`yard-details-popup-container`}>
        <div className="yard-details-popup-top-section">
          <h3>
            Yard Details{"("}
            {yardData["locationName"] !== null
              ? yardData["locationName"]
              : null}
            {")"}
          </h3>
          <div className="yard-modal-action-buttons">
            <div
              onClick={() => modalCloseHandler()}
              className="yard-modal-action-button-wrapper"
            >
              <img className="yard-modal-action-button" src={closeButton} />
            </div>
          </div>
        </div>
        <div className="yard-details-popup-bottom-section">
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">SCAC</p>
            <p className="yard-details-item-value">
              {yardData["scac"] !== null ? yardData["scac"] : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Trailer Number</p>
            <p className="yard-details-item-value">
              {yardData["trailerNumber"] !== null
                ? yardData["trailerNumber"]
                : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Check-in Time</p>
            <p className="yard-details-item-value">
              {yardData["checkInTime"] !== null
                ? convertTimestampIntoHHMM(yardData["checkInTime"])
                : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Load Type</p>
            <p className="yard-details-item-value">
              {yardData["loadType"] !== null ? yardData["loadType"] : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Status</p>
            <p className="yard-details-item-value">
              {yardData["status"] !== null ? yardData["status"] : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Pickup Appt.</p>
            <p className="yard-details-item-value">
              {yardData["pickupTime"] !== null
                ? convertTimestampIntoHHMM(yardData["pickupTime"])
                : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Past Pickup</p>
            <p className="yard-details-item-value">
              {yardData["latePickup"] !== null ? yardData["latePickup"] : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Shipment/Trip Number</p>
            <p className="yard-details-item-value">
              {yardData["shipmentNumber"] !== null
                ? yardData["shipmentNumber"]
                : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Distribution/Delivery no</p>
            <p className="yard-details-item-value">
              {yardData["deliveryNumber"] !== null
                ? yardData["deliveryNumber"]
                : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Comments</p>
            <p className="yard-details-item-value">
              {yardData["comments"] !== null ? yardData["comments"] : null}
            </p>
          </div>
          <div className="yard-bottom-item-container">
            <p className="yard-details-title">Percentage</p>
            <p className="yard-details-item-value">
              {yardData["progressPercentage"] !== null
                ? yardData["progressPercentage"]
                : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YardDetails;
