import '../../../assets/css/TimeMachineController/timeMachineController.css';
import playButton from '../../../assets/icons/play.png';
import pauseButton from '../../../assets/icons/pauseIcon.png';
import forwardButton from '../../../assets/icons/forward15.png';
import backwardButton from '../../../assets/icons/backward15.png';
import downArrow from '../../../assets/icons/downArrow.png';
import upArrow from '../../../assets/icons/upArrow.png';
import {useContext, useEffect, useState} from 'react';
import { AppContext } from '../../../Context/Context';
import { getLocalStorageItem, setLocalStorageItem, timeStampMinuteToHourFormatter } from '../../../util/helper/helperFunctions';
import { timeFromInt } from 'time-number';
import { Slider } from 'antd';

const TimeMachineController = () => {
    const {timeMachineControllerIsOpen, setTimeMachineControllerIsOpen} = useContext(AppContext);
    const {timeMachineState, setTimeMachineState} = useContext(AppContext);
    const {timeMachineLoader, setTimeMachineLoader} = useContext(AppContext);
    const [totalSeekPosition, setTotalSeekPosition] = useState(0);
    const [currentSeekPosition, setCurrentSeekPosition] = useState(0);
    const {currentPageCount, setCurrentPageCount} = useContext(AppContext);

    useEffect(() => {
        if(localStorage.getItem('seekPosition') && localStorage.getItem('totalSeekPosition')) {
            setCurrentSeekPosition(JSON.parse(localStorage.getItem('seekPosition')));
            setTotalSeekPosition(JSON.parse(localStorage.getItem('totalSeekPosition')));
        }
    }, [localStorage.getItem('seekPosition'), localStorage.getItem('totalSeekPosition')])

    const seekBarChangeHandler = (e) => {
        setTimeMachineLoader(true);
        setCurrentSeekPosition(e);
        setCurrentPageCount(e);
        setLocalStorageItem('seekPosition', Number(e));
        setLocalStorageItem('groupDataCurrentCount', 0);
        setLocalStorageItem('groupDataCount', 0);
    }

    const playPauseTimeMachine = () => {
        setTimeMachineState(!timeMachineState);
        let timeMachineDataAvailable = JSON.parse(localStorage.getItem('timeMachineDataAvailable'));
        setLocalStorageItem('groupDataCurrentCount', 0);
        setLocalStorageItem('groupDataCount', 0);
        if(timeMachineDataAvailable == false) {
            localStorage.getItem('timeMachineDataAvailable', JSON.stringify(true));
        } else if (timeMachineDataAvailable == true) {
            localStorage.getItem('timeMachineDataAvailable', JSON.stringify(false));
        }
    }

    const backward15Seconds = () => {
        setTimeMachineLoader(true);
        let currentSeekPosition = JSON.parse(localStorage.getItem('seekPosition'));
        let backwarded15SecondsPosition = Number(currentSeekPosition) - 15;
        if(backwarded15SecondsPosition <= 0) {
            setLocalStorageItem('seekPosition', 1)
            setCurrentPageCount(1);
        } else {
            setLocalStorageItem('seekPosition', backwarded15SecondsPosition);
            setCurrentPageCount(backwarded15SecondsPosition);
            setLocalStorageItem('groupDataCurrentCount', 0);
            setLocalStorageItem('groupDataCount', 0);
        }
    }

    const forwarded15Seconds = () => {
        setTimeMachineLoader(true);
        let currentSeekPosition = JSON.parse(localStorage.getItem('seekPosition'));
        let forwarded15SecondsPosition = Number(currentSeekPosition) + 15;
        if(forwarded15SecondsPosition >= getLocalStorageItem('totalSeekPosition')) {
            setLocalStorageItem('seekPosition', getLocalStorageItem('totalSeekPosition'))
            setCurrentPageCount(getLocalStorageItem('totalSeekPosition'));
            setTimeMachineState(false);
        } else {
            setLocalStorageItem('seekPosition', forwarded15SecondsPosition);
            setCurrentPageCount(forwarded15SecondsPosition);
            setLocalStorageItem('groupDataCurrentCount', 0);
            setLocalStorageItem('groupDataCount', 0);
        }
    }

    const getCurrentSeekPosition = () => {
        return getLocalStorageItem('seekPosition') - 1;
    }

    const getTotalSeekPosition = () => {
        return getLocalStorageItem('totalSeekPosition');
    }

    return (
        <>
            {timeMachineControllerIsOpen ? 
                <div className="time-machine-controller-container">
                    <div className="time-machine-sections">
                        <div className="time-machine-controller-top-section">
                            <Slider 
                                className='time-machine-seek-bar' 
                                name='timeMachineSeekbar' 
                                id='timeMachineSeekbarId' 
                                min={0} 
                                max={totalSeekPosition} 
                                value={currentSeekPosition} 
                                onAfterChange={(e) => seekBarChangeHandler(e)}
                                draggableTrack={true}
                                controlSize={20}
                                railSize={200}
                                dotActiveBorderColor='#4461F2'
                                dotBorderColor='#4461F2'
                                handleActiveColor='#EEEEEE'
                                handleColor='#EEEEEE'
                                trackBg='#EEEEEE'
                                trackHoverBg='#EEEEEE'
                            />
                        </div>
                        <div className="time-machine-controller-bottom-section">
                            <div className='time-bar-section'>
                                {timeStampMinuteToHourFormatter(timeFromInt(currentSeekPosition))} / {timeStampMinuteToHourFormatter(timeFromInt(totalSeekPosition))}
                            </div>
                            <div className='control-buttons-section'>
                                <img 
                                    onClick={() => backward15Seconds()} className={`${timeMachineLoader || getCurrentSeekPosition() == 0 ? 'no-action-allowed' : "time-machine-fast-forward-btn"}`}
                                    src={backwardButton}/>
                                {timeMachineState ? <img 
                                    src={pauseButton} 
                                    onClick={() => playPauseTimeMachine()} 
                                    className={`${timeMachineLoader ? 'no-action-allowed' : 'time-machine-play-btn'}`} /> : <img onClick={() => playPauseTimeMachine()} 
                                    className={`${timeMachineLoader ? 'no-action-allowed' : 'time-machine-play-btn'}`} src={playButton}/>
                                }
                                <img onClick={() => forwarded15Seconds()} 
                                className={`${timeMachineLoader || getCurrentSeekPosition() == getTotalSeekPosition() ? 'no-action-allowed' : 'time-machine-fast-forward-btn'}`} src={forwardButton}/>
                            </div>
                            <div onClick={() => setTimeMachineControllerIsOpen(!timeMachineControllerIsOpen)} className='toggle-controller-section'>
                                <h4>Hide Controls</h4>
                                <img src={downArrow}/>
                            </div> 
                        </div>
                    </div>
                </div>
                : <div className='small-controller-toggle-container'>
                    <div onClick={() => setTimeMachineControllerIsOpen(!timeMachineControllerIsOpen)} className='small-controller-toggle-section'>
                        <img src={upArrow}/>
                        <h4>Open Controls</h4>
                    </div>
                </div>
            }
        </>
    )
}

export default TimeMachineController;