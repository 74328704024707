import axios from "axios";

const getApi = async (url, headers) => {
  try {
    if (url) {
      let respData = await axios.get(url, headers);
      if (respData?.status == 200) {
        return respData;
      } else {
        return { msg: "Something went wrong" };
      }
    }
  } catch (err) {
    return err;
  }
};

const postApi = async (url, payload, headers) => {
  try {
    if (url && payload) {
      let postCallResp = await axios.post(url, payload, headers);
      if (postCallResp?.status == 200) {
        return postCallResp;
      } else {
        return { msg: "Something went wrong" };
      }
    }
  } catch (err) {
    console.log("ERR - POST API", err);
  }
};

export { getApi, postApi };
