import React, { useContext } from "react";
import "../../assets/css/Header/header.css";

import Button from "../Reusables/Button/Button";
import Dropdown from "../Reusables/Dropdown/Dropdown";
import Arrow from "../../assets/icons/arrow.png";
import LeftArrow from "../../assets/icons/leftArrow.png";
import LeftArrowTail from "../../assets/icons/arrowLeft.png";
import InfoSmall from "../../assets/icons/infoSmall.png";
import DownArrowTail from "../../assets/icons/downArrow.png";
import { AppContext } from "../../Context/Context";
import { reloadApp } from "../../util/helper/helperFunctions";

const Header = () => {
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);
  const {
    timeMachineState,
    setTimeMachineState,
    activeLGVModule,
    setYardSummaryModalIsOpen,
    setIsYardLegendsPopupOpen,
  } = useContext(AppContext);
  const { timeMachineTimeFrames, setTimeMachineTimeFrames } =
    useContext(AppContext);
  const { timeRangePopup, setTimeRangePopup } = useContext(AppContext);
  const { customizePopup, setCustomizePopup } = useContext(AppContext);
  const { warehouseModelIsOpen, setWarehouseModelIsOpen } =
    useContext(AppContext);
  const { layoutPopup, setLayoutPopup } = useContext(AppContext);

  const {
    showSaveDiscardLayout,
    setShowSaveDiscardLayout,
    saveCustomizedLayout,
    discardCustomizedLayout,
  } = useContext(AppContext);

  const exitTimeMachineMode = () => {
    setTimeMachineModeIsEnabled(false);
    localStorage.setItem("isTimeMachineModeEnabled", "false");
    setTimeMachineState(false);
    setTimeMachineTimeFrames({
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
    });
    reloadApp();
  };

  const openWarehouseModal = () => {
    setWarehouseModelIsOpen(!warehouseModelIsOpen);
    document.body.classList.add("prevent-scroll");
  };

  const renderHeadingBasedOnRoute = () => {
    switch (activeLGVModule) {
      case "yard-view":
        return "Yard View";
      case "allocated-view":
        return "Allocated View";
      default:
        return "Dashboard";
    }
  };

  return (
    <div className="header-container">
      <div className="title-section">
        {timeMachineModeIsEnabled ? (
          <div className="time-machine-header-topic">
            <img onClick={() => exitTimeMachineMode()} src={LeftArrowTail} />
            <h3>Time Machine</h3>
          </div>
        ) : (
          <h3>{renderHeadingBasedOnRoute()}</h3>
        )}
      </div>
      <div className="time-options-section">
        {activeLGVModule == "allocated-view" ? null : activeLGVModule ==
          "yard-view" ? (
          <>
            <Button
              placeholder="Legend"
              btnClass="reverse-content"
              imgClass="reverse-content-img"
              icon={InfoSmall}
              onClick={() => setIsYardLegendsPopupOpen(true)}
            />

            <Button
              placeholder="Yard Summary"
              btnClass="reverse-content"
              imgClass="reverse-content-img"
              icon={Arrow}
              onClick={() => setYardSummaryModalIsOpen(true)}
            />
          </>
        ) : timeMachineModeIsEnabled ? (
          <div
            onClick={() => setTimeRangePopup(!timeRangePopup)}
            className="time-frame-displayer"
          >
            <div className="from-date">
              {timeMachineTimeFrames.fromDate} -{" "}
              {timeMachineTimeFrames.fromTime}
            </div>
            to
            <div className="to-date">
              {timeMachineTimeFrames.toDate} - {timeMachineTimeFrames.toTime}
            </div>
          </div>
        ) : showSaveDiscardLayout ? (
          <div className="options-section">
            <Button
              placeholder="Discard"
              btnClass="reverse-content customize-btn"
              imgClass="reverse-content-img"
              onClick={() => discardCustomizedLayout()}
            />
            <Button
              placeholder="Save Layout"
              btnClass="reverse-content customize-btn selected"
              imgClass="reverse-content-img"
              onClick={() => saveCustomizedLayout()}
            />
          </div>
        ) : (
          <div className="options-section">
            <Button
              placeholder="Layout"
              icon={Arrow}
              btnClass="reverse-content customize-btn"
              imgClass="reverse-content-img"
              onClick={() => setLayoutPopup(!layoutPopup)}
            />
            <Button
              placeholder="Customize"
              icon={Arrow}
              btnClass="reverse-content customize-btn"
              imgClass="reverse-content-img"
              onClick={() => setCustomizePopup(!customizePopup)}
            />
            <Button
              placeholder="Warehouse Info"
              icon={LeftArrow}
              btnClass="reverse-content customize-btn warehouse-btn"
              imgClass="reverse-content-img"
              onClick={() => openWarehouseModal()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
