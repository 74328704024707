import React, { useEffect, useState } from "react";
import "../../assets/css/Status/removedStatus.css";
import infoIcon from "../../assets/icons/info.png";
import {
  getLocalStorageItem,
  onDragging,
  onDraggingEnd,
} from "../../util/helper/helperFunctions";
const RemovedStatus = ({ title, scrollable, socket }) => {
  const [removedStatusLoader, setRemovedStatusLoader] = useState(true);
  const [removedStatusData, setRemovedStatusData] = useState({});

  useEffect(() => {
    let auth = getLocalStorageItem("auth");
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (auth !== null && locationCodeLocal) {
      const initiateFleetStatusWebSocket = () => {
        socket.on(`${locationCodeLocal}_LGV_FLEET_STATUS`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== "Error connecting to datasource!"
          ) {
            if (data.results.lgvStatus.length !== 0) {
              setRemovedStatusData((removedStatusData) => ({
                ...removedStatusData,
                fleetStatus: data.results.lgvStatus,
              }));
            }
          }
        });
      };

      const initiateFleetCountWebSocket = () => {
        socket.on(`${locationCodeLocal}_LGV_FLEET_COUNT`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== "Error connecting to datasource!"
          ) {
            if (data.results.fleetData.length !== 0) {
              setRemovedStatusData((removedStatusData) => ({
                ...removedStatusData,
                fleetCount: data.results.fleetData,
              }));
            }
          }
        });
      };
      initiateFleetStatusWebSocket();
      initiateFleetCountWebSocket();
    }
  }, [
    socket,
    getLocalStorageItem("locationCode"),
    removedStatusData.fleetStatus,
    removedStatusData.fleetCount,
  ]);

  useEffect(() => {
    if (
      (removedStatusData?.fleetStatus !== undefined &&
        removedStatusData?.fleetStatus.length !== 0) ||
      (removedStatusData?.fleetCount !== undefined &&
        removedStatusData?.fleetCount.length !== 0)
    ) {
      setRemovedStatusLoader(false);
    }
  }, [removedStatusData.fleetStatus, removedStatusData.fleetCount]);

  const renderFleetStatusAndCount = (removedStatusData) => {
    if (removedStatusData.fleetCount && removedStatusData.fleetStatus) {
      return (
        <p data-testid="empty-removed-lgv">
          {removedStatusData.fleetStatus.length} LGVs Out of System (Fleet Size:{" "}
          {removedStatusData.fleetCount[0]?.fleetSize})
        </p>
      );
    } else {
      return <p>0 LGVs Out of System (Fleet Size: 0)</p>;
    }
  };

  const loader = () => {
    return (
      <div className="rm-loader">
        <h4 data-testid="loading-text">Loading...</h4>
        <div className="spinner"></div>
      </div>
    );
  };

  const renderFleetObjects = (removedStatusData) => {
    if (
      removedStatusData.fleetStatus &&
      removedStatusData.fleetStatus.length !== 0
    ) {
      return (
        <div
          className={`rm-status-section ${
            scrollable ? "rm-status-section-wrap" : ""
          } `}
        >
          {removedStatusData.fleetStatus?.map((status, index) => {
            if (status) {
              return (
                <div
                  data-testid="empty-removed-lgv"
                  key={index}
                  className="status-box"
                >
                  <div className="status-top-section">
                    <p>
                      <span data-testid="fleet-status-test-id">LGV:</span>{" "}
                      {status.lgv}
                    </p>
                  </div>
                  <div className="status-bottom-section">
                    <p>
                      {status.duration.split(" ")[0]}{" "}
                      <span>{status.duration.split(" ")[1]}</span>
                    </p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div
          className={`rm-status-section ${
            scrollable ? "rm-status-section-wrap" : ""
          } `}
        >
          <div class="empty-removed-status-container">
            <p>No Removed LGVs at the moment.</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      onMouseDown={(event) => onDragging(event)}
      onMouseUp={(event) => onDraggingEnd(event)}
      className="rm-lgv-fleet-status-container"
    >
      {removedStatusLoader ? (
        loader()
      ) : (
        <>
          <div className="fleet-container">
            <div className="fleet-status">
              <img src={infoIcon} />
              {renderFleetStatusAndCount(removedStatusData)}
            </div>
          </div>

          <div className="rm-status-container">
            <div className="rm-header-section">
              <h4>{title}</h4>
            </div>
            {renderFleetObjects(removedStatusData)}
          </div>
        </>
      )}
    </div>
  );
};

export default RemovedStatus;
