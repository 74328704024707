import { AppContext } from "../../../Context/Context";
import { useContext, useEffect, useState } from "react";
import Close from "../../../assets/icons/close.png";
import "./Layout.css";
import { getLocalStorageItem } from "../../../util/helper/helperFunctions";
const LayoutPopup = () => {
  const { layoutPopup, setLayoutPopup, isMapDragEnable, setIsMapDragEnable } =
    useContext(AppContext);

  const {
    showSaveDiscardLayout,
    setShowSaveDiscardLayout,
    setSingnal,
    isDefaultLayoutSelected,
    setIsDefaultLayoutSelected,
  } = useContext(AppContext);

  useEffect(() => {
    setShowSaveDiscardLayout(showSaveDiscardLayout);
  }, [showSaveDiscardLayout]);

  // State to track selected layout

  useEffect(() => {
    if (getLocalStorageItem("layout-order")) {
      setIsDefaultLayoutSelected(false);
    }
  }, []);

  const handleSelectLayout = (status) => {
    if (isDefaultLayoutSelected == status) {
      setLayoutPopup(false);
      return;
    }

    if (status == false) {
      setIsMapDragEnable(false);
      document.body.classList.remove("no-drag");
    }

    setLayoutPopup(false);
    setIsDefaultLayoutSelected(status);
    setShowSaveDiscardLayout(true);
  };

  return (
    <>
      <div className="customize-wrapper customize-layout" id="customize-layout">
        <div className="layout-container ">
          <div className="customize-modal-top-section">
            <h2>Layout</h2>
            <img onClick={() => setLayoutPopup(!layoutPopup)} src={Close} />
          </div>
          <div className="layout-selection-wrapper">
            <div
              className={`layout-option ${
                isDefaultLayoutSelected && !getLocalStorageItem("layout-order")
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelectLayout(true)}
            >
              <div className="layout-text-container">
                <div className="left-content">
                  <span className="checkbox">
                    <svg
                      width="22"
                      height="15"
                      viewBox="0 0 22 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.73426 11.7L19.0343 0.4C19.3009 0.133333 19.612 0 19.9676 0C20.3232 0 20.6343 0.133333 20.9009 0.4C21.1676 0.666667 21.3009 0.983333 21.3009 1.35C21.3009 1.71667 21.1676 2.03333 20.9009 2.3L8.66759 14.5667C8.40093 14.8333 8.08982 14.9667 7.73426 14.9667C7.37871 14.9667 7.06759 14.8333 6.80093 14.5667L1.06759 8.83333C0.800927 8.56667 0.673149 8.25 0.68426 7.88333C0.695372 7.51667 0.83426 7.2 1.10093 6.93333C1.36759 6.66667 1.68426 6.53333 2.05093 6.53333C2.41759 6.53333 2.73426 6.66667 3.00093 6.93333L7.73426 11.7Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
                <div className="right-content">
                  <h3>Default</h3>
                  <p>Standard LGV One layout</p>
                </div>
              </div>
            </div>

            <div
              className={`layout-option ${
                !isDefaultLayoutSelected && getLocalStorageItem("layout-order")
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelectLayout(false)}
            >
              <div className="layout-text-container">
                <div className="left-content">
                  <span className="checkbox">
                    <svg
                      width="22"
                      height="15"
                      viewBox="0 0 22 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.73426 11.7L19.0343 0.4C19.3009 0.133333 19.612 0 19.9676 0C20.3232 0 20.6343 0.133333 20.9009 0.4C21.1676 0.666667 21.3009 0.983333 21.3009 1.35C21.3009 1.71667 21.1676 2.03333 20.9009 2.3L8.66759 14.5667C8.40093 14.8333 8.08982 14.9667 7.73426 14.9667C7.37871 14.9667 7.06759 14.8333 6.80093 14.5667L1.06759 8.83333C0.800927 8.56667 0.673149 8.25 0.68426 7.88333C0.695372 7.51667 0.83426 7.2 1.10093 6.93333C1.36759 6.66667 1.68426 6.53333 2.05093 6.53333C2.41759 6.53333 2.73426 6.66667 3.00093 6.93333L7.73426 11.7Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
                <div className="right-content">
                  <h3>Custom</h3>
                  <p>Customize the layout according to your preferences</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LayoutPopup;
