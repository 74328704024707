import { useContext } from 'react';
import '../../../assets/css/Heatmap/heatmapModal.css';
import { AppContext } from '../../../Context/Context';
const Loader = ({children}) => {
    const {heatmapLoader, setHeatmapLoader} = useContext(AppContext);
    const {loaderText, setLoaderText} = useContext(AppContext);
    return (
        <div className='heat-map-loader'>
            {heatmapLoader ? 
                <div className='spinner-wrapper'>
                    <h3>{loaderText}</h3>
                    {loaderText.includes('No Data') ? <h3 className='loader-text'>Please select a different Timerange!</h3> : null}
                    {loaderText.includes('No Data') ? null : <div className='spinner'></div>}
                </div>
            : null}
            {children}
        </div>
    )
}

export default Loader;