import { useState } from "react";
import "./toggleSwitch.css";

const ToggleSwitch = ({ alarmToggle, sensorToggle }) => {
  const [switchState, setSwitchState] = useState({
    alarm: true,
    sensor: false,
  });
  const alarmSwitchHandler = () => {
    alarmToggle();
    if (!switchState.alarm) {
      setSwitchState((switchState) => ({
        alarm: !switchState.alarm,
        sensor: !switchState.sensor,
      }));
    }
  };

  const sensorsSwitchHandler = () => {
    sensorToggle();
    if (!switchState.sensor) {
      setSwitchState((switchState) => ({
        alarm: !switchState.alarm,
        sensor: !switchState.sensor,
      }));
    }
  };
  return (
    <div className="toggle-switch-wrapper">
      <div className="toggle-switch-container">
        <div
          className={`toggle-switch-button ${
            switchState.alarm ? "toggle-switch-on" : ""
          }`}
          onClick={() => alarmSwitchHandler()}
        >
          Alarms
        </div>
        <div
          className={`toggle-switch-button ${
            switchState.sensor ? "toggle-switch-on" : ""
          }`}
          onClick={() => sensorsSwitchHandler()}
        >
          Sensors
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
