import { Tooltip } from 'antd';
import '../../assets/css/BackendConnectivity/backendConnectivity.css';
const BackendConnectivity = ({status}) => {
    return (
        <div className="network-wrapper">
            <div className="network-container">
                <Tooltip placement="left" title={status ? 'Online' : 'Offline'}>
                    <div className={`network-status ${status ? 'network-connected' : 'network-not-connected'}`}></div>
                </Tooltip>
            </div>
        </div>
    )
}
export default BackendConnectivity;