import "../../assets/css/DockDoor/dockDoor.css";

const YardDockIcon = ({ dockData, legendColors }) => {
  let {
    progressPercentage: percentage,
    locationStatus,
    trailerStatus,
  } = dockData;
  let isHorizontal = dockData.xLength > dockData.yLength;
  let progressStyle = isHorizontal
    ? { width: `${percentage}%`, height: "3px" }
    : { width: "3px", height: `${percentage}%` };

  let locationColor =
    locationStatus == "Default" ? "white" : legendColors[locationStatus];
  let trailerColor = legendColors[trailerStatus];

  return (
    <div className="yard-dock-icon-container">
      <div
        className="yard-dock-top-section"
        style={{ backgroundColor: trailerColor }}
      >
        <div className="yard-dock-prgress-wrapper">
          <div className="yard-dock-progress-container">
            <div className="yard-dock-progress" style={progressStyle}></div>
          </div>
        </div>
        <div className="yard-dock-top-section-number">
          {dockData.trailerStatus === "UNAVAILABLE" ||
          dockData.trailerStatus === "PENDING" ? (
            dockData.trailerStatus
          ) : (
            <>
              {dockData.scac} {dockData.trailerNumber}
            </>
          )}
        </div>
      </div>
      <div
        className="yard-dock-bottom-section"
        style={{ backgroundColor: locationColor }}
      >
        <div className="yard-dock-small">{dockData.locationName}</div>
      </div>
    </div>
  );
};

export default YardDockIcon;
