import { useState, useEffect } from "react";
import { formatTime } from "../../../util/helper/helperFunctions";

const DockDoor = ({ dockDoor, data, backgroundColor, textColor }) => {
  const { trailerNumber, status, palletsRequested, palletsStaged, duration } =
    data;
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransition((prev) => !prev);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const renderUiByStatus = () => {
    const STATIC_STATUS = ["P", "C", "S", "H"];
    if (transition || STATIC_STATUS.includes(status)) {
      return (
        <div className={`container`}>
          <div className="status">
            <h1>{status}</h1>
          </div>
          <div className="dock-door-info" style={{ color: textColor }}>
            <div>
              <h1>DOOR {dockDoor}</h1>
            </div>
            <div>{trailerNumber}</div>
          </div>
        </div>
      );
    } else {
      if (status === "E") {
        return (
          <div>
            <h1 style={{ color: textColor }}>
              {palletsStaged}/{palletsRequested}
            </h1>
          </div>
        );
      }
      if (status === "A")
        return (
          <div>
            <h1 style={{ color: textColor }}>{formatTime(duration)}</h1>
          </div>
        );
    }
  };

  return (
    <div className="dock-door" style={{ backgroundColor }}>
      {renderUiByStatus()}
    </div>
  );
};

export default DockDoor;
