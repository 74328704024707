import { useContext, useEffect, useState } from "react";
import "../../assets/css/BatteryStation/batteryStation.css";

const BatteryStationObject = ({ stationData, index }) => {
  let stationNameText = `${stationData.stationName[0]}${
    stationData.stationName.split("_")[1]
  }`;
  const statusColor =
    stationData.status === "Enabled"
      ? "#238749"
      : stationData.status === "Disabled"
      ? "#FFC0CB"
      : "#1839E0";

  const [isBatteryStationPopupOpen, setIsBatteryStationPopupOpen] =
    useState(false);

  const openModal = (index) => {
    setIsBatteryStationPopupOpen(!isBatteryStationPopupOpen);
  };

  const closeModal = (index) => {
    setIsBatteryStationPopupOpen(!isBatteryStationPopupOpen);
  };

  return (
    <div
      key={index}
      onClick={() => openModal(index)}
      className="batt-station-container"
      style={{
        backgroundColor: statusColor,
      }}
    >
      <span className="battery-station-name-text">{stationNameText}</span>
    </div>
  );
};
export default BatteryStationObject;
