export const lgvIconStyles = `
    display:flex;
    background-color: #2b2b2b;
    width:18px;
    height:18px;
    border:1px solid #ffffff;
    border-radius:2px;
    font-size: 12px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: 'Poppins' !important;
    z-index: 1008 !important;
`;

export const userDataObject = {
  businessPhones: [],
  displayName: "",
  givenName: "",
  jobTitle: null,
  mail: "",
  mobilePhone: null,
  officeLocation: "",
  preferredLanguage: null,
  surname: "",
  userPrincipalName: "",
  id: "",
};
export const heatmapHorizontalCount = 25;
export const heatmapVerticalCount = 57;

export const plantCarLatSize = 27;
export const plantCarLngSize = 27;

export const heatMapGridX = -1750.3403361344538;
export const heatMapGridY = 538.1567444620252;

export const defaultMarkerData = {
  vehicleNumber: "1",
  status: "null",
  source: "null",
  destination: "null",
  segment: "null",
  currentPos: { lat: 1, lng: 1 },
  duration: "1",
  alarmName: "null",
  sku: "1",
  lpn: "1",
};

export const noDataDockDetails = {
  DOOR: "No Data Found",
  STATUS: "No Data Found",
  TYPE: "No Data Found",
  SHIPMENT: "No Data Found",
  LOADTIME: 0,
  ALLOCATED: 0,
  RELEASED: 0,
  ACTIVE: 0,
  REMAINPALL: 0,
  CURRENT_DATE_TIME: "No Data Found",
  PROCESSTIME: 0,
  PROGRESS: 0,
};

export const getColorPallets = () => {
  return [
    {
      name: "LGV LEGENDS",
      colors: {
        Available: "#008000",
        Loaded: "#0000FF",
        "In Operation/traffic": "#D96E0B",
        Deadlock: "#EF1FDA",
        Lost: "#B9B9B9",
        Removed: "#000000",
        "In Alarm": "#EE3232",
        "In Manual": "#800000",
        Charging: "#83C4D9",
      },
    },
    {
      name: "PRODUCTION LINE LEGENDS",
      colors: {
        "Line Running": "#4133E1",
        "Pallets waiting for pickup": "#42C721",
        "# LGVs to pickup at line": "#238749",
      },
    },
    {
      name: "TRAILER PALLET LEGENDS",
      colors: {
        Completed: "#42C721",
        Released: "#E1CC0F",
        Allocated: "#B9B9B9",
        "In Transit/Started": "#4133E1",
        "Can Allocate": "#D90000",
        New: "#FF5733",
      },
    },
  ];
};

export const dummyProductionLine = [
  {
    LINE_NAME: "L1",
    STATUS: "Active",
    ITEM: "MMK05L40PDMPCNR02",
    JOB_NUMBER: "L00878253",
    CASES_SCHEDULED: 37536,
    CASES_PRODUCED: 8112,
    CASES_REMAINING: 29424,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 1,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 275639,
    LATITUDE: 37930,
  },
  {
    LINE_NAME: "L2",
    STATUS: "Active",
    ITEM: "CLO05L32PDRPCN",
    JOB_NUMBER: "L00879370",
    CASES_SCHEDULED: 10860,
    CASES_PRODUCED: 9420,
    CASES_REMAINING: 1440,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 254550,
    LATITUDE: 37970,
  },
  {
    LINE_NAME: "L3",
    STATUS: "Active",
    ITEM: "GRV05L40PDMPCN",
    JOB_NUMBER: "L00879877",
    CASES_SCHEDULED: 47280,
    CASES_PRODUCED: 37008,
    CASES_REMAINING: 10272,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 149253,
    LATITUDE: 38006,
  },
  {
    LINE_NAME: "L4",
    STATUS: "Active",
    ITEM: "GRV05L40PDMPCN",
    JOB_NUMBER: "L00879877",
    CASES_SCHEDULED: 47280,
    CASES_PRODUCED: 37008,
    CASES_REMAINING: 10272,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 149253,
    LATITUDE: 38006,
  },
];

export const singleBlockData = [
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
];

export const mockLgv = [
  {
    vehicleNumber: "19",
    source: "IB.MASS.HNDOFF.02",
    status: "ActiveFull",
    longitude: "342014",
    latitude: "40199",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "42",
    source: "IB.MASS.HNDOFF.02",
    status: "ActiveFull",
    longitude: "259027",
    latitude: "102065",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "9",
    source: "IB.MASS.HNDOFF.02",
    status: "Traffic",
    longitude: "499519",
    latitude: "44851",
    duration: 0.57,
    alarmName: "LGV_Yielding_Block\t",
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "45",
    source: "L3.FNSH.PCKUP.01",
    status: "ActiveFull",
    longitude: "311600",
    latitude: "115168",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00300275412092424702",
    lpn_2: "00300275412092424719",
    destination: "E.E.013",
    destinationLongitude: 434352,
    destinationLatitude: 71655,
  },
  {
    vehicleNumber: "29",
    source: "L5.FNSH.PCKUP.01",
    status: "ActiveFull",
    longitude: "324535",
    latitude: "115154",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00500275412095041450",
    lpn_2: "00500275412095041467",
    destination: "H.W.012",
    destinationLongitude: 366314,
    destinationLatitude: 154855,
  },
  {
    vehicleNumber: "34",
    source: "G.W.017",
    status: "ActiveFull",
    longitude: "327109",
    latitude: "125799",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "STAGE.045",
    destinationLongitude: 247632,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "49",
    source: "G.W.017",
    status: "ActiveFull",
    longitude: "247665",
    latitude: "34206",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00400275412095005240",
    lpn_2: "00400275412095005301",
    destination: "STAGE.045",
    destinationLongitude: 247632,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "35",
    source: "G.E.013",
    status: "ActiveFull",
    longitude: "319765",
    latitude: "40168",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "STAGE.046",
    destinationLongitude: 251519,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "7",
    source: "G.E.013",
    status: "ActiveFull",
    longitude: "251629",
    latitude: "25955",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00300275412092360406",
    lpn_2: "00300275412092360413",
    destination: "STAGE.046",
    destinationLongitude: 251519,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "17",
    source: "SSR.045",
    status: "ActiveFull",
    longitude: "267462",
    latitude: "33443",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00500275412095037996",
    lpn_2: null,
    destination: "STAGE.049",
    destinationLongitude: 267442,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "10",
    source: "IB.MASS.HNDOFF.01",
    status: "ActiveFull",
    longitude: "400828",
    latitude: "40161",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "12",
    source: "IB.MASS.HNDOFF.01",
    status: "ActiveFull",
    longitude: "174091",
    latitude: "40178",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "14",
    source: "IB.MASS.HNDOFF.01",
    status: "Alarm",
    longitude: "159953",
    latitude: "50796",
    duration: 1.82,
    alarmName: "(A14.00) TimeOut_Tilt_Up\t",
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "41",
    source: "SSR.034",
    status: "ActiveFull",
    longitude: "283963",
    latitude: "40171",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "SSR.050",
    destinationLongitude: 252286,
    destinationLatitude: 34047,
  },
  {
    vehicleNumber: "1",
    source: null,
    status: "Idle",
    longitude: "329915",
    latitude: "74621",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "11",
    source: null,
    status: "Idle",
    longitude: "404189",
    latitude: "77743",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "13",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 81338.98,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "15",
    source: null,
    status: "Idle",
    longitude: "406982",
    latitude: "103672",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "16",
    source: null,
    status: "Idle",
    longitude: "329931",
    latitude: "103673",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "18",
    source: null,
    status: "Charging",
    longitude: "328408",
    latitude: "33641",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "53",
    source: null,
    status: "Idle",
    longitude: "481483",
    latitude: "75244",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "6",
    source: null,
    status: "Idle",
    longitude: "240841",
    latitude: "86769",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "8",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1472503.17,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "47",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 73071.52,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "48",
    source: null,
    status: "Idle",
    longitude: "166379",
    latitude: "102490",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "5",
    source: null,
    status: "Idle",
    longitude: "327064",
    latitude: "89528",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "50",
    source: null,
    status: "Alarm",
    longitude: "300877",
    latitude: "134381",
    duration: 44.77,
    alarmName: "(A2.00) Front PLS\t",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "51",
    source: null,
    status: "Idle",
    longitude: "242963",
    latitude: "37629",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "52",
    source: null,
    status: "Idle",
    longitude: "329308",
    latitude: "116025",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "39",
    source: null,
    status: "Idle",
    longitude: "432964",
    latitude: "115132",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "4",
    source: null,
    status: "Idle",
    longitude: "348530",
    latitude: "119511",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "40",
    source: null,
    status: "Idle",
    longitude: "123289",
    latitude: "116655",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "43",
    source: null,
    status: "Charging",
    longitude: "199071",
    latitude: "123300",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "44",
    source: null,
    status: "Idle",
    longitude: "404182",
    latitude: "56291",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "46",
    source: null,
    status: "Idle",
    longitude: "356027",
    latitude: "115160",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "31",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1190465.42,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "32",
    source: null,
    status: "Idle",
    longitude: "377744",
    latitude: "119489",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "33",
    source: null,
    status: "Idle",
    longitude: "496845",
    latitude: "47757",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "36",
    source: null,
    status: "Idle",
    longitude: "425490",
    latitude: "119515",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "37",
    source: null,
    status: "Idle",
    longitude: "313751",
    latitude: "36427",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "38",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1064208.4,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "25",
    source: null,
    status: "Idle",
    longitude: "404158",
    latitude: "103333",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "26",
    source: null,
    status: "Idle",
    longitude: "478643",
    latitude: "54239",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "27",
    source: null,
    status: "Idle",
    longitude: "203886",
    latitude: "120346",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "28",
    source: null,
    status: "Idle",
    longitude: "407013",
    latitude: "54540",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "3",
    source: null,
    status: "Idle",
    longitude: "406998",
    latitude: "84547",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "30",
    source: null,
    status: "Idle",
    longitude: "457620",
    latitude: "119520",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "2",
    source: null,
    status: "Idle",
    longitude: "145372",
    latitude: "120325",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "20",
    source: null,
    status: "Idle",
    longitude: "301833",
    latitude: "36434",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "21",
    source: null,
    status: "Idle",
    longitude: "465118",
    latitude: "115152",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "22",
    source: null,
    status: "Idle",
    longitude: "328553",
    latitude: "168131",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "23",
    source: null,
    status: "Idle",
    longitude: "385243",
    latitude: "115165",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "24",
    source: null,
    status: "Idle",
    longitude: "329559",
    latitude: "45761",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
];

export const mockPalletData = {
  location: "mock",
  position: 1,
  row: 2,
  level: 1,
  lpn: "00800275410100072393",
  item: "LESS15AK95V03TRIALS4",
  itemType: "LABL",
  qty: 220,
  lot: null,
  productionDate: "2023-04-03T07:57:00.000Z",
  expirationDate: "2024-10-05T00:00:00.000Z",
};
