import { useContext, useEffect, useState } from "react";
import "../../assets/css/DockDoor/dockDoor.css";
import ExportIcon from "../../assets/icons/export.png";
import InfoSmall from "../../assets/icons/infoSmall.png";
import { AppContext } from "../../Context/Context";
import Button from "../Reusables/Button/Button";
import LegendsPopup from "../Modal/LegendPopup/LegendPopup";
import L, { marker } from "leaflet";
import { Map } from "react-leaflet";
import DockIcon from "./DockIcon";
import ReactDOMServer from "react-dom/server";
import DockDoorPopup from "../Modal/DockDoorPopup/DockDoorPopup";
import {
  findDockDoorPosition,
  mapCoordinate,
} from "../../util/helper/helperFunctions";
import {
  getAdjustedDockDoorCoordinates,
  externalLinkNavigator,
} from "../../util/helper/helperFunctions";

const DockDoor = () => {
  const {
    isLegendsPopupOpen,
    setIsLegendsPopupOpen,
    allocatedView,
    setAllocatedView,
  } = useContext(AppContext);

  const openLegendsPopup = () => {
    setIsLegendsPopupOpen(true);
    document.body.classList.add("prevent-scroll");
  };

  return (
    <div className={`dock-door-container`}>
      <Button
        onClick={() => openLegendsPopup()}
        placeholder="Legend"
        btnClass="legend-btn"
        icon={InfoSmall}
      />
    </div>
  );
};

export default DockDoor;
