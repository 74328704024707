import { useContext, useRef } from "react";
import { AppContext } from "../../../Context/Context";
import Close from "../../../assets/icons/closeButton.png";
import "../../../assets/css/YardView/YardSummary.css";

const YardSummary = ({ summaryData }) => {
  const { setYardSummaryModalIsOpen } = useContext(AppContext);
  const popupRef = useRef(null);

  document.addEventListener("click", (e) => {
    if (e.target == popupRef.current) {
      setYardSummaryModalIsOpen(false);
    }
  });

  return (
    <div
      ref={popupRef}
      id="yard-summary-modal-wrapper-id"
      className="yard-summary-modal-wrapper"
    >
      <div className="yard-summary-modal">
        <div className="yard-summary-modal-top-section">
          <h3>Yard Summary</h3>
          <img src={Close} onClick={() => setYardSummaryModalIsOpen(false)} />
        </div>
        <div className="yard-summary-modal-bottom-section">
          {summaryData ? (
            <div className="yard-bottom-item-container">
              <div class="yard-summary-shift-box">
                <div class="yard-summary-shift-box-top-section">
                  <p>{summaryData.yardCapacity}</p>
                </div>
                <div class="yard-summary-shift-box-bottom-section">
                  <p>Yard Capacity</p>
                </div>
              </div>
              <div class="yard-summary-shift-box">
                <div class="yard-summary-shift-box-top-section">
                  <p>{summaryData.totalTrailers}</p>
                </div>
                <div class="yard-summary-shift-box-bottom-section">
                  <p>Trailers in Yard</p>
                </div>
              </div>
              <div class="yard-summary-shift-box">
                <div class="yard-summary-shift-box-top-section">
                  <p>{summaryData.emptySlots}</p>
                </div>
                <div class="yard-summary-shift-box-bottom-section">
                  <p>Empty Slots</p>
                </div>
              </div>
              <div class="yard-summary-shift-box">
                <div class="yard-summary-shift-box-top-section">
                  <p>{summaryData.utilizationPercentage}%</p>
                </div>
                <div class="yard-summary-shift-box-bottom-section">
                  <p>Utilization</p>
                </div>
              </div>
            </div>
          ) : (
            "Loading Yard Summary..."
          )}
        </div>
      </div>
    </div>
  );
};

export default YardSummary;
