import { useContext, useState } from 'react';
import '../../../assets/css/Legends/legends.css';
import closeButton from '../../../assets/icons/closeButton.png';
import { AppContext } from '../../../Context/Context';
import { getColorPallets } from '../../../util/helper/constants';

const LegendsPopup = () => {

    const {isLegendsPopupOpen, setIsLegendsPopupOpen} = useContext(AppContext);
    const [colorPallets, setColorPallets] = useState(getColorPallets());

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('legend-popup-wrapper-id')) {
            closePopup();
        }
    })

    const closePopup = () => {
        setIsLegendsPopupOpen(false);
        document.body.classList.remove('prevent-scroll');
    }

    return <div id='legend-popup-wrapper-id' className='legend-popup-wrapper'>
        <div className='legend-popup-container'>
            <div className='legends-top-section'>
                <h4>Legends</h4>
                <img onClick={() => closePopup()} src={closeButton}/>
            </div>
            {colorPallets.map((colorData) => {
                return <>
                <div className='legends-bottom-section'>
                    <div className='colors-section'>
                        <div className='colors-top-section'>
                            <h4>{colorData['name']}</h4>
                        </div>
                        <div className='colors-bottom-section'>
                            {Object.entries(colorData['colors']).map(([key, value]) => {
                                return <div key={key} className='color-pallet'>
                                    <div style={{backgroundColor: `${value}`}} className='legend-color'></div>
                                    <div className='legend-name'>{key}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                </>
            })}
        </div>
    </div>
}

export default LegendsPopup;