import closeButton from "../../../assets/icons/downArrow.png";
import { useContext, useState } from "react";
import { AppContext } from "../../../Context/Context";
import "../../../assets/css/AllocatedView/LegendView.css";
const LegendPopup = () => {
  const {
    allocatedView,
    setAllocatedView,
    allocatedViewLegend,
    setAllocatedViewLegend,
  } = useContext(AppContext);

  const LEGENDS_CONFIGS = [
    {
      symbol: "P",
      text: "Pending",
      colorName: "Orange",
      displayColor: "#E77400",
    },
    {
      symbol: "A",
      text: "Allocated",
      colorName: "Yellow",
      displayColor: "#EEC909",
    },
    {
      symbol: "E",
      text: "Executing",
      colorName: "Green",
      displayColor: "#38AB00",
    },
    {
      symbol: "H",
      text: "Paused",
      colorName: "Light Green",
      displayColor: "#01CC1F",
    },
    {
      symbol: "C",
      text: "Complete",
      colorName: "Dark Green",
      displayColor: "#238749",
    },
    {
      symbol: "S",
      text: "Stalled",
      colorName: "Red",
      displayColor: "#BF2E3A",
    },
  ];
  return (
    <div id="legend-popup-wrapper-id" className="legend-popup-wrapper">
      <div className="legend-popup-container custom-width">
        <div
          className="view-modal-container"
          onClick={() => setAllocatedViewLegend((prev) => !prev)}
        >
          <img alt="close modal" src={closeButton} />
          <h4>Hide Legends</h4>
        </div>
        <div className="legend-list">
          {LEGENDS_CONFIGS.map(({ symbol, text, colorName, displayColor }) => {
            return (
              <div className="legend-container">
                <div>{text}</div>
                <div className="legend-info">
                  <div className="view-symbol">{symbol}</div>
                  <div
                    style={{ background: displayColor }}
                    className="view-legend-color"
                  ></div>
                  <div>{colorName}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default LegendPopup;
