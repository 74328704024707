import LocationIcon from '../../../assets/icons/location.png';
import './location.css';
import Button from '../Button/Button';
import MapModal from '../../Modal/MapModal/MapModal';
import { useEffect, useState } from 'react';
import { getLocalStorageItem } from '../../../util/helper/helperFunctions';

const Location = ({onClick, location, allLocations}) => {
    const getLocationPlaceholder = () => {
        if(!allLocations) {
            return 'Loading...';
        } else {
            if(location.length !== 0) {
                return getLocalStorageItem('locationCode');
            } else {
                return 'Select Location';
            }
        }
    }
    return(
        <Button 
            btnClass={`${!allLocations ? 'loading': ''}`} 
            onClick={onClick} 
            icon={LocationIcon} 
            placeholder={getLocationPlaceholder()}
        />
    )
}

export default Location;