import { useContext, useRef } from "react";
import "../../../assets/css/YardView/YardLegends.css";
import closeButton from "../../../assets/icons/closeButton.png";
import { AppContext } from "../../../Context/Context";

const YardLegendsPopup = ({ legendData }) => {
  const popupRef = useRef(null);
  const { setIsYardLegendsPopupOpen } = useContext(AppContext);

  document.addEventListener("click", (e) => {
    if (e.target == popupRef.current) {
      closePopup();
    }
  });

  const closePopup = () => {
    setIsYardLegendsPopupOpen(false);
    document.body.classList.remove("prevent-scroll");
  };

  return (
    <div
      ref={popupRef}
      id="yard-legend-popup-wrapper-id"
      className="yard-legend-popup-wrapper"
    >
      <div className="yard-legend-popup-container">
        <div className="yard-legends-top-section">
          <h4>Legends</h4>
          <img onClick={() => closePopup()} src={closeButton} />
        </div>
        {legendData !== null ? (
          legendData.map((colorData) => {
            return (
              <>
                <div className="yard-legends-bottom-section">
                  <div className="yard-colors-section">
                    <div className="yard-colors-top-section">
                      <h4>{colorData["name"]}</h4>
                    </div>
                    <div className="yard-colors-bottom-section">
                      {Object.entries(colorData["colors"]).map(
                        ([key, value]) => {
                          return (
                            <div key={key} className="yard-color-pallet">
                              <div
                                style={{ backgroundColor: `${value.color}` }}
                                className="yard-legend-color"
                              ></div>
                              <div className="yard-legend-name">
                                {value.title}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <>Loading Legend...</>
        )}
      </div>
    </div>
  );
};

export default YardLegendsPopup;
